import { useEffect } from "react"

// styles
import "./LoadingScreen.scss"

const SteinStore = require("stein-js-client");
const store = new SteinStore(
  "https://api.steinhq.com/v1/storages/64b6edfbeced9b09e9e1d5ff"
);


export default function({
  showStep, //goal 
  stepCounter, 
  setStepCounter,
  setAsesors,
  asesors,
}) {
  useEffect(() => {
    if(stepCounter === 3) {
      //until api answers
      const getAsesors = async () => {
        await store
          .read("Asesores", {authentication: {
            username: 'asesor',
            password: 'clave12345678909876543211123456789999999987654'
          }})
          .then(data => {
            setAsesors(data);
            setStepCounter(4)
          })
          .catch(e => {
            setStepCounter(1)
            console.error(e);
          });
      }

      getAsesors()
    }
  }, [stepCounter])
  
  return (<div className={`loadingScreen ${stepCounter === showStep && "active"}`}>
    <h1 className="h1 ls_ti">Buscándote tu asesor más cercano...</h1>
  </div>)
}