export default function({
  title,
  desc,
  ilustration,
}) {

  return(
    <section className="step-wrap-container">
      <div className="step-container">
        <div style={{height: "13px"}} />
        <h1 className="h1">{title}</h1>

        <div style={{height: "15px"}} />
        <h2 className="h2">{desc}</h2>

        <div style={{height: "32px"}} />
        <img className="ilustration" src={ilustration} />
      </div>
    </section>
  )
}