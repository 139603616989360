// react
import React from 'react';
import ReactDOM from 'react-dom';
import { StrictMode } from 'react';

// components
import App from './App';

// prime react
//theme
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";     
//core
import "primereact/resources/primereact.min.css";
//icons
import "primeicons/primeicons.css";  

ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root')
);