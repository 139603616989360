// styles
import "./Input.scss"

export default function({
  placeholder,
  value,
  onChange,
  type,
  pattern,
}) {
  return(<input
    className="Input"
    placeholder={placeholder}
    value={value}
    onChange={onChange}
    type={type}
    pattern={pattern}
  />)
}