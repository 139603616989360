// styles
import "./FormTemplate.scss"

export default function({
  children,
  className
}) {
  return(
    <section className={`step-wrap-container ${className}`}>
      <div className="step-container --form">
        {children}
      </div>
    </section>
  )
}