import { useEffect } from "react"

// src
import mapImg from "../../../img/concepto_mapa.jpg"

// cmp
import StepTemplate from "../../../Components/StepTemplate/StepTemplate"

export default function({setAbleNextStep}) {
  useEffect(() => {
    setAbleNextStep(true)
  }, [])
  
  return(
    <StepTemplate 
      title={"Buscaremos un asesor para ti"}
      // desc={"Para personalizarlo <br/>te haremos algunas preguntas..."}
      desc={"Contesta las siguientes preguntas..."}
      ilustration={mapImg}
    />
  )
}