import { useEffect, useState } from "react";

// src
import logo from "../src/img/logo.png"

// components
import Step2 from "./Pages/Steps/Step2/Step2";
import Step3 from "./Pages/Steps/Step3/Step3";
import Step4 from "./Pages/Steps/Step4/Step4";
import Step5 from "./Pages/Steps/Step5/Step5";
import StepBtn from "./Components/StepBtn/StepBtn";
import LoadingScreen from "./Pages/LoadingScreen/LoadingScreen";
import CTAScreen from "./Pages/CTAScreen/CTAScreen";

import Swal from 'sweetalert2';
import axios from "axios";

// styles
import "./Styles/Globals.scss"
import "./Styles/Steps.scss"
import "./Styles/Layout.scss"

function App() {
  const [leadData, setLeadData] = useState({
    name: "",
    age: "",
    phone_number: "",
    lat: null,
    lng: null,
  })
  const [asesors, setAsesors] = useState([])
  const [stepCounter, setStepCounter] = useState(1)
  const [ableNextStep, setAbleNextStep] = useState(true)
  const FINAL_FORM_SLIDE = 3;

  const updateLeadData = (
      property, 
      value, 
      p2, 
      v2, 
      p3, 
      v3,
      p4, 
      v4,
    ) => {
    const copy = {...leadData};
    copy[property] = value;
    
    if(p2 && v2) {
      copy[p2] = v2;
    }

    if(p3 && v3) {
      copy[p3] = v3;
    }

    if(p4 && v4) {
      copy[p4] = v4;
    }

    setLeadData(copy)
  }

  // props packs
  const StepsProps = {stepCounter, setStepCounter, ableNextStep, setAbleNextStep}
  const AsesorsProps = {asesors, setAsesors}
  const LeadData = {updateLeadData, leadData, setLeadData}
  
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const { latitude, longitude } = position.coords;
          updateLeadData("lat", latitude, "lng", longitude)
        },
        error => {
          // alert("Necesitamos acceso a tu ubicación para encontrar tu asesor más cercano")
          console.error(error);
          updateLeadData("lat", 0, "lng", 0)
        }
        );
      } else {
        alert("La geolocalización no es soportada por éste navegador :(")
        console.error('Geolocation is not supported by this browser.');
        updateLeadData("lat", 0, "lng", 0)
    }
  }, []);

  useEffect(() => {
    function esValorNumerico(inputValue) {
      return /^\d+$/.test(inputValue);
    }

    const validateBackupPC = async () => {
      const pcValue = document.getElementById("backupPC").value;
      const isNumeric = esValorNumerico(pcValue)
      let isValid = null;
      
      async function obtenerCoordenadas(codigoPostal) {
        try {
          const xwu = "A" + "I" + "z" + "a" + "S" + "y" + "B" + "Y" + "n" + "5" + "0" + "R" + "c" + "4" + "1" + "e" + "B" + "S" + "N" + "V" + "z" + "Z" + "Y" + "b" + "Q" + "0" + "i" + "A" + "W" + "f" + "b" + "p" + "H" + "L" + "F" + "h" + "j" + "z" + "E";
          
          const response = await axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
            params: {
              address: `México ${codigoPostal}`,
              key: xwu
            }
          });
          
          const results = response.data.results;
          if (results && results.length > 0) {
            const location = results[0].geometry.location;
            const latitud = location.lat;
            const longitud = location.lng;
            
            isValid = true
            return { latitud, longitud };
          } else {
            throw new Error('No se encontraron resultados para el código postal especificado');
          }
        } catch (error) {
          console.error('Error al obtener las coordenadas:', error.message);
          isValid = false;
        }
      }
      
      
      if(pcValue && isNumeric) {
        let cords;
        
        await obtenerCoordenadas(pcValue)
        .then(coordenadas => {
          cords = coordenadas;
        });

        if(isValid) {
          const { latitud, longitud } = cords;
          updateLeadData("lat", latitud, "lng", longitud)
        } else {
          Swal.showValidationMessage("Ingresa un código postal válido")
        }

      } else {
        Swal.showValidationMessage("Ingresa un código postal válido")
      }
    }
    
    if(leadData.lat === 0) {
      Swal.fire({
        customClass: {
          confirmButton: "backup_confirm",
          actions: "backup_confirm_actions",
        },
        title: 'Necesitamos acceso a tu ubicación',
        icon: 'info',
        timerProgressBar: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        confirmButtonText: "Buscar",
        showCloseButton: false,
        html: `
          <div class="swal2-html-container swal2-html-containerFirst" id="swal2-html-container" style="display: block;">
            Active su ubicación, otorgue acceso a la página y actualícela.
          </div>
          <div class="swal2-html-container" id="swal2-html-container" style="display: block;">
            O puede ingresar su código postal:
          </div>
          <input placeholder="Escribe tu código postal" pattern="[0-9]" id="backupPC" />
        `,
        preConfirm: validateBackupPC,
      })
    }
  }, [leadData])
  

  return (<div className="layout">
    <div className="bg-overlay"/>
    
    <header className="Header">
      <img onClick={()=>window.location.reload()} src={logo}/>
    </header>

    
      <main className="App">
        <StepBtn disabled={stepCounter <= 1 || stepCounter > FINAL_FORM_SLIDE} {...StepsProps} direction="left" />

        {stepCounter == 1 && <Step2 {...StepsProps}/>}
        {(stepCounter == 2 || stepCounter == FINAL_FORM_SLIDE) && <Step3 {...LeadData} {...StepsProps}/>} 
        
        {(stepCounter == 2 || stepCounter == FINAL_FORM_SLIDE || stepCounter == 4) && <LoadingScreen showStep={FINAL_FORM_SLIDE} {...StepsProps} {...AsesorsProps}/>}
        {stepCounter > FINAL_FORM_SLIDE && <CTAScreen {...LeadData} {...AsesorsProps} />} 
                
        
        <StepBtn disabled={!leadData.lat || !ableNextStep || stepCounter > FINAL_FORM_SLIDE} {...StepsProps} direction="right" />

        <div className="mobile-actions">
          <StepBtn disabled={stepCounter <= 0 || stepCounter > FINAL_FORM_SLIDE} {...StepsProps} direction="left" />
          <StepBtn disabled={!leadData.lat || !ableNextStep || stepCounter > FINAL_FORM_SLIDE} {...StepsProps} direction="right" />
        </div>
      </main>

    
  </div>);
}

export default App;
