import React from "react";
import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs,
} from "react-google-maps";

const Map = withScriptjs(
  withGoogleMap((props) => (
    <GoogleMap
      defaultZoom={15}
      defaultCenter={{ lat: props.lat, lng: props.lng }}
    >
      <Marker key={props.lat} position={{ lat: props.lat, lng: props.lng }} />

      {/* this is the client location */}
      {/* <Marker key={props.lat2} position={{ lat: props.lat2, lng: props.lng2 }} /> */}
    </GoogleMap>
  ))
);

function MapComponent({ lat, lng, lat2, lng2 }) {
  
  return (
    <Map
      // googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`}
      key={lng}
      googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${"AIzaSyBYn50Rc41eBSNVzZYbQ0iAWfbpHLFhjzE"}`}
      loadingElement={<div style={{ height: "400px", width: "400px" }} />}
      containerElement={<div style={{ height: "400px", width: "400px" }} />}
      mapElement={<div style={{ height: "400px", width: "400px" }} />}
      lat={lat}
      lng={lng}
      lat2={lat2}
      lng2={lng2}
    />
  );
}

export default MapComponent;
