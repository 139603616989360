// styles
import "./StepBtn.scss"

export default function({
  direction="right",
  disabled=false,
  stepCounter,
  setStepCounter,
}) {
  
  const handleClick = () => {
    !disabled &&  
    direction === "right" 
      ? setStepCounter(stepCounter+1) 
      : setStepCounter(stepCounter-1)
  }
  
  return (
    <div className={`step-btn ${disabled && "disabled"}`} onClick={handleClick}>
      <i className={`sbi pi pi-arrow-${direction}`}></i>
    </div>
  )
}