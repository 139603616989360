// react
import { useEffect, useState } from "react";

// components
import FormTemplate from "../../../Components/FormTemplate/FormTemplate";
import Input from "../../../Components/Input/Input";


export default function({updateLeadData, setAbleNextStep}) {
  const [value, setValue] = useState("")
  const [value1, setValue1] = useState("")
  const [value2, setValue2] = useState("")


  useEffect(() => {
    if(value && value1 && value2) {
      
      setAbleNextStep(true)
      updateLeadData("name", value, "age", value1, "phone_number", value2)
    } else {
      setAbleNextStep(false)
    }
  }, [value, value1, value2])

  return(<FormTemplate>
    <h3 className="h3">¿Cuál es tu nombre?</h3>
    
    <div style={{height: "10px"}} />
    
    <Input
      placeholder={""}
      value={value}
      onChange={e=>setValue(e.target.value)}
    />

    <div style={{height: "30px"}} />
    
    <h3 className="h3">Edad</h3>
    <div style={{height: "10px"}} />
    <Input
      pattern="[0-9]{1,2}"
      placeholder={""}
      value={value1}
      onChange={e=>setValue1((v) => (e.target.validity.valid ? e.target.value : v))}
    />

    <div style={{height: "30px"}} />
    
    <h3 className="h3">Número telefónico</h3>
    <div style={{height: "10px"}} />
    <Input
      pattern="[0-9]{1,15}"
      placeholder={""}
      value={value2}
      onChange={e=>setValue2((v) => (e.target.validity.valid ? e.target.value : v))}
    />
    
  </FormTemplate>)
}