import Swal from 'sweetalert2';
import { useEffect, useState } from "react"

// styles
import "./CTAScreen.scss"

// components
import FormTemplate from "../../Components/FormTemplate/FormTemplate"
import ResearchScreen from "../ResearchScreen/ResearchScreen"
import MapComponent from '../../Components/Map/GoogleMap';

// db
import SteinStore from 'stein-js-client';

//asesores
import asesoresDB from "./Asesores.json";

export default function({leadData, asesors, setAsesors}) {
  const store = new SteinStore(
    "https://api.steinhq.com/v1/storages/6480c73cd27cdd09f0f923b1"
  );

  // variables
  const [asesor, setAsesor] = useState({})
  const [asesores, setAsesores] = useState(asesoresDB);

  useEffect(() => console.log(asesors, "from the api"), [asesors])

  // functions
  function eliminarObjetoDeArreglo(objeto, arreglo, setArreglo) {
    const indice = arreglo.findIndex(elemento => elemento === objeto);
    
    if (indice !== -1) {
      const copy = [...arreglo]
      copy.splice(indice, 1);
      setArreglo(copy)
    }
  } 

  const pickNextNearestOffice = () => {
    // detect the nearest office and delete it from the array
    const {distancia, oficina} = calcularDistancia(leadData, asesors)
    console.log("esta estructura necesitamos", asesors);
    setAsesor(oficina)
    eliminarObjetoDeArreglo(oficina, asesors, setAsesors)
  } 

  function topFunction() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  } 

  const researchOffice = () => {
    document.getElementsByClassName("ResearchScreen")[0].classList.add("active"); 
    document.body.style.overflow = "hidden";

    setTimeout(() => {topFunction(); pickNextNearestOffice()}, 1000)
    
    setTimeout(() => {document.getElementsByClassName("ResearchScreen")[0].classList.remove("active")}, 2000)
    setTimeout(() => {document.body.style.overflow = "visible"}, 2500)
  } 

  const openScheduleModal = () => {
    const loadingSwal = Swal.fire({
      title: 'Contactando asesor...',
      text: 'Por favor espere un momento',
      icon: 'info',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      showCloseButton: false,
      didOpen: () => {
        Swal.showLoading();
      }
    })

    const whatsUrl = `https://api.whatsapp.com/send?phone=${asesor.Celular}&text=Buen%20d%C3%ADa%2C%20me%20llamo%20${encodeURIComponent(leadData.name)}%20tengo%20${encodeURIComponent(leadData.age)}%20a%C3%B1os%20y%20los%20contacto%20porque%20me%20interesa%20recibir%20asesor%C3%ADa.`
    
    store.append("interesados", [leadData])
      .then(() => {
        setTimeout(() => {
          loadingSwal.close()
          window.location.href = whatsUrl;
        }, 1000)
      })
      .catch(error => {
        loadingSwal.close()
        window.location.href = whatsUrl;
        console.error(error)
      });
  } 

  function distanciaEntreCoordenadas(lat1, lng1, lat2, lng2) {
    const rad = Math.PI / 180;
    const dLat = rad * (lat2 - lat1);
    const dLng = rad * (lng2 - lng1);
    const r = 6372.8; // km
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(rad * lat1) *
        Math.cos(rad * lat2) *
        Math.sin(dLng / 2) *
        Math.sin(dLng / 2);
    const c = 2 * Math.asin(Math.sqrt(a));
    return r * c;
  } 
  
  function calcularDistancia(posicionActual, oficinas) {
    let minDistancia = Infinity;
    let oficinaCercana = null;
    
    oficinas.forEach((oficina) => {
      const distancia = distanciaEntreCoordenadas(
        posicionActual.lat,
        posicionActual.lng,
        oficina.lat,
        oficina.lng
        );
        if (distancia < minDistancia) {
          minDistancia = distancia;
          oficinaCercana = oficina;
        }
      });
      
    return { distancia: minDistancia, oficina: oficinaCercana };
  } 

  
  // useEffect  
  useEffect(() => {
    pickNextNearestOffice()
  }, [])
    

  return (<FormTemplate className={`CTAScreen`}>
    <div style={{height: "60px"}} />
    <h1 className="h1">Tu asesor se llama {asesor.Nombre}</h1>


    <div className="mobileDivisor" />
    <h2 className="h2">Te puede ayudar de manera presencial o en línea</h2>
    <div style={{height: "40px"}} />



    <div className="mapContainer">
      <MapComponent 
        lat={parseFloat(asesor.lat)}
        lng={parseFloat(asesor.lng)}
        lat2={leadData.lat}
        lng2={leadData.lng}
      />
    </div>


    <div style={{height: "40px"}} />
    <div className="actions">
      {
        !!asesors.length &&
        <button onClick={() => researchOffice()} href="" className="cta cta2">Solicitar otra oficina</button>
      }

      <a href="#" onClick={openScheduleModal} className="cta">Contactar asesor</a>
    </div>
    <div style={{height: "100px"}} />
    
    <ResearchScreen />
  </FormTemplate>)
}